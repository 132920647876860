@media (min-width:768px) {
    .confirm-window .modal-dialog {
        width: 450px !important;
    }
}
@media (max-width:767px) {
    .modal-lg {
        width: auto !important;
    }
    input[type="search"] {
        width: 100% !important;
    }
}